// extracted by mini-css-extract-plugin
export var bestiaryWrapper = "monsters-module--bestiaryWrapper--r5BrL";
export var searchResults = "monsters-module--searchResults--IZEOh";
export var monster = "monsters-module--monster--X511I";
export var stats = "monsters-module--stats--JRuxI";
export var long = "monsters-module--long--rz29+";
export var source = "monsters-module--source--JqxS6";
export var abilities = "monsters-module--abilities--4KeAl";
export var ability = "monsters-module--ability--md95q";
export var modifier = "monsters-module--modifier--QAVmM";
export var short = "monsters-module--short--lBJFQ";